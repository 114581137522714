































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import GraphNode from '@/models/GraphNode';
import GraphLink from '@/models/GraphLink';

@Component
export default class RangeMenu extends Vue {

  @Prop({ default: null })
  private options!: {
    weight: number,
    groups: Array<{ com: string, color: string, isShow: boolean }>,
  };

  private selectWeight: string = '0';
  private menuPos: any = { top: 0, left: 0 };

  private mounted() {
    // 画面中央一番下に配置
    const app = document.getElementById('app');
    const rangeBox = document.getElementsByClassName('range-box')[0];
    this.menuPos.top = app && rangeBox ? app.clientHeight - rangeBox.clientHeight : 0;
    this.menuPos.left = app && rangeBox ? (app.clientWidth - rangeBox.clientWidth) * 0.5 : 0;
    this.selectWeight = (this.options.weight * 100).toString();
  }

  @Watch('selectWeight')
  private changeSelectWeight() {
    this.$emit('changeSelectWeight', Number(this.selectWeight) / 100);
  }

  /**
   * メニューのドラッグ移動
   */
  private dragStart(e: any): void {
    const clickStartPos = Object.assign({}, this.menuPos);
    const clickPos = { x: e.screenX, y: e.screenY };

    const drag = (e2: any) => {
      this.menuPos.left = clickStartPos.left + e2.screenX - clickPos.x;
      this.menuPos.top = clickStartPos.top + e2.screenY - clickPos.y;
    };
    const dragEnd = () => {
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('mouseup', dragEnd);
    };
    document.addEventListener('mousemove', drag);
    document.addEventListener('mouseup', dragEnd);
  }

  get posStyle() {
    return 'top: ' + this.menuPos.top + 'px; left: ' + this.menuPos.left + 'px';
  }
}
