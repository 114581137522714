






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {

  private message: string = '';
  private top: number = 0;
  private left: number = 0;
  private timerId: number = -1;

  private mounted() {
    // ツールチップを有効にする
    const elements = Array.from(document.getElementsByClassName('has-tooltip'));
    elements.map((ele: Element) => {
      if ((ele as HTMLElement).dataset.message) {
        ele.addEventListener('mouseover', this.hoverEvent);
        ele.addEventListener('mouseleave', this.leaveEvent);
      }
    });
  }

  /**
   * ホバー時にツールチップの表示（500msのディレイ有り）
   */
  private hoverEvent(e: any) {
    const target = (e.target as HTMLElement);
    const rect = target.getBoundingClientRect();
    this.top = rect.bottom - rect.height * 0.5;
    this.left = rect.x + rect.width * 0.5;
    this.message = target.dataset.message ? target.dataset.message : '';
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
      this.timerId = setTimeout(() => { tooltip.classList.add('show'); }, 500);
    }
  }

  /**
   * ホバー解除時にツールチップの非表示に
   */
  private leaveEvent(e: any) {
    const tooltip = document.getElementById('tooltip');
    if (tooltip) { tooltip.classList.remove('show'); }
    if (this.timerId !== -1) { clearTimeout(this.timerId); }
  }
}
