/**
 * グラフに関する定数設定
 */
export const graphConst = {
  groupColorList: [
    '#ff6666', '#ff66b2', '#ff66ff', '#b266ff', '#6666ff', '#66b2ff', '#66ffff', '#66ffb2', '#66ff66', '#b2ff66', '#ffff66', '#ffb266',
    '#ff7a7a', '#ff7abc', '#ff7aff', '#bc7aff', '#7a7aff', '#7abcff', '#7affff', '#7affbc', '#7aff7a', '#bcff7a', '#ffff7a', '#ffbc7a',
  ],
  menu: {
    startPos: {
      x: 60,
      y: 250,
    },
  },
  circle: {
    minRange: 10,           // 最小直径
    rateRange: 1.75,        // 直径の補正倍率
    opacity: 0.75,          // 透明度
    strokeColor: '#ffffff', // 枠線の色
    strokeWidth: 1,         // 枠線の幅
  },
  label: {
    size: 12,               // 文字サイズ
    strongSize: 16,         // 強調されているときの文字サイズ
    weight: 'bold',         // 文字の太さ
    textAnchor: 'middle',   // 文字の位置
    color: '#545454',       // 文字の色
    shadowWidth: 2,         // 文字の影幅
    shadowColor: '#ffffff', // 文字の影色
  },
  line: {
    color: '#999999',       // 色
    opacity: 0.7,           // 透明度
    minWeight: 1.0,         // 最小幅
    rateWeight: 5.0,        // 幅の補正倍率
    selectColor: '#666',    // 選択同士の線の色
  },
  env: {
    targetIdName: 'network-graph',
    friction: 0.9,      // 摩擦力（1.0が最大）
    charge: -350,       // 反発力
    minScale: 1.0,      // 最小の拡縮
    maxScale: 4.0,      // 最大の拡大
    startLinkNum: 60,   // リンクの初期表示数
  },
  notSelectionNodeOpacity: 0.5, // 選択していないノードの透明度
};
