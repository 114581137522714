


































import { Component, Prop, Vue } from 'vue-property-decorator';
import Tooltip from './../atoms/Tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class HeaderMenu extends Vue {

  @Prop({ default: null })
  private summary!: {
    competitors: boolean[],
    landing_page: boolean,
    top10: boolean,
    user_page: boolean,
  };

  @Prop({ default: null })
  private target!: string;

  /**
   * グラフのURLを生成（callbackURLのtargetの値を変更して返す）
   */
  private changeGraphUrl(target: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get('callback_url');
    if (!callbackUrl) { return ''; }
    const url = new URL(callbackUrl);
    url.searchParams.set('target', target);
    urlParams.set('callback_url', url.href);
    return window.location.origin + '?' + urlParams.toString();
  }
}
