






import { Component, Vue } from 'vue-property-decorator';
import TopPage from './components/pages/TopPage.vue';

@Component({
  components: {
    TopPage,
  },
})
export default class App extends Vue {}
