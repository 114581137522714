










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ErrorScreen extends Vue {

  @Prop({ default: null })
  private message!: string;
}
