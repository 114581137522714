















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { graphConst } from '@/define';
import GraphNode from '@/models/GraphNode';
import GraphLink from '@/models/GraphLink';

@Component
export default class ToolMenu extends Vue {

  @Prop({ default: null })
  private options!: {
    weight: number,
    groups: Array<{ com: string, color: string, isShow: boolean }>,
  };

  private selectGroups: Array<{ com: string, color: string, isShow: boolean }> = [];
  private menuPos: any = { top: graphConst.menu.startPos.y, left: graphConst.menu.startPos.x };

  /**
   * 初期化
   */
  private mounted() {
    this.selectGroups = this.options.groups;
  }

  /**
   * グループの選択状況が変更されたら親へ
   */
  @Watch('selectGroups', { deep: true })
  private changeSelectGroups() {
    this.$emit('changeSelectGroups', this.selectGroups);
  }

  /**
   * メニューのドラッグ移動
   */
  private dragStart(e: any): void {
    const clickStartPos = Object.assign({}, this.menuPos);
    const clickPos = { x: e.screenX, y: e.screenY };

    const drag = (e2: any) => {
      this.menuPos.left = clickStartPos.left + e2.screenX - clickPos.x;
      this.menuPos.top = clickStartPos.top + e2.screenY - clickPos.y;
    };
    const dragEnd = () => {
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('mouseup', dragEnd);
    };
    document.addEventListener('mousemove', drag);
    document.addEventListener('mouseup', dragEnd);
  }

  /**
   * メニュー位置のCSS
   */
  get posStyle() {
    return 'top: ' + this.menuPos.top + 'px; left: ' + this.menuPos.left + 'px';
  }
}
