






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { graphConst } from '@/define';
import * as d3 from 'd3'; // package.jsonにd3と@types/d3両方ないとエラーが消えない
import GraphNode from '@/models/GraphNode';
import GraphLink from '@/models/GraphLink';
import Vector2 from '@/models/Vector2';

@Component
export default class GraphScreen extends Vue {
  @Prop({ default: null })
  private data!: {
    nodes: GraphNode[],
    links: GraphLink[],
  };

  @Prop({ default: null })
  private options!: {
    weight: number,
    groups: Array<{ com: string, color: string, isShow: boolean }>,
  };

  private targetIdName: string = graphConst.env.targetIdName;
  private lineConst = graphConst.line;
  private circleConst = graphConst.circle;
  private labelConst = graphConst.label;

  private nodeClickFlag: boolean = false;  // nodeのクリックかを監視するフラグ
  private viewRect: string = '0 0 100 100';
  private viewTransRect: {
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    width: number,
    height: number,
    } = {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: 0,
      width: 0,
      height: 0,
    };
  private viewScale: number = 1.0;
  private windowHeight: number = window.innerHeight;

  /**
   * 初期化
   */
  private mounted() {

    const target = document.getElementById(this.targetIdName);
    if (!target) {
      return;
    }

    // ビューポートとsvg座標の初期化
    const width = target.clientWidth;
    const height = window.innerHeight - target.getBoundingClientRect().y - 10;
    this.viewRect = '0 0 ' + width + ' ' + height;
    this.viewTransRect.x2 = width;
    this.viewTransRect.y2 = height;
    this.viewTransRect.width = width;
    this.viewTransRect.height = height;

    // ネットワークグラフの環境設定
    const simulation = d3.forceSimulation(this.data.nodes)
      .velocityDecay(graphConst.env.friction)                               // 摩擦力
      .force('link', d3.forceLink(this.data.links).id((d) => (d as GraphNode).id))
      .force('charge', d3.forceManyBody().strength(graphConst.env.charge))  // 反発力
      .force('collision', d3.forceCollide()                       // 要素の重なり設定
        .radius((d) => {
          const range = (d as GraphNode).size * this.circleConst.rateRange;
          return range < this.circleConst.minRange ? this.circleConst.minRange : range;
         }))
      .force('center', d3.forceCenter(width / 2, height / 2));

    // ノードの設定
    this.data.nodes.map((node) => {
      node.isShow = true;
      node.isStrong = false;
      node.isSelect = false;
    });

    // d3.jsの設定
    const svg = d3.select(target).select('svg');
    const links = svg.select('.links').selectAll('line').data(this.data.links);
    const nodes = svg.select('.nodes').selectAll('circle').data(this.data.nodes);
    const labels = svg.select('.labels').selectAll('g').data(this.data.nodes);

    this.changeOptions();

    // 動作開始！
    simulation.on('tick', () => {
      links
        .attr('x1', (d) => (d.source as GraphNode).x)
        .attr('y1', (d) => height - (d.source as GraphNode).y)
        .attr('x2', (d) => (d.target as GraphNode).x)
        .attr('y2', (d) => height - (d.target as GraphNode).y);
      nodes
        .attr('cx', (d) => d.x)
        .attr('cy', (d) => height - d.y);
      labels
        .attr('transform', (d) => {
          return 'translate(' + d.x + ',' + (height - d.y) + ')'; });
    });
  }

  /**
   * optionの変更による表示・非表示の処理
   */
  @Watch('options', { deep: true })
  private changeOptions() {
    const weight = Number(this.options.weight);
    const group = this.options.groups;

     // 表示・非表示フラグ付与
    const links = this.data.links.map((link) => {
      link.isShow = link.percentile >= weight;
      return link;
    });

    const nodes = this.data.nodes.map((node) => {
      node.isShow = true;
      const targetLinks = links.filter((link) => {
        const t = link.target as GraphNode;
        const s = link.source as GraphNode;
        return t.id === node.id || s.id === node.id;
      });
      if (targetLinks.length && targetLinks.every((link) => !link.isShow)) {
        node.isShow = false;
      }
      const targetGroup = group.find((g) => g.com === node.com);
      if (!targetGroup || !targetGroup.isShow) {
        node.isShow = false;
      }
      return node;
    });

    // データの更新
    this.data.nodes = nodes;
    this.data.links = links;
  }

  /**
   * 関連キーワードの強調
   * @param {*} node
   */
  private strongNodeEntities(node: GraphNode) {

    // 強調するノードの設定
    const nodes = this.data.nodes.map((n) => {
      n.isSelect = false;
      if (n.id === node.id) {
        n.isSelect = true;
      }
      return n;
    });

    // リンクから強調するノードの設定（クリックしたワードと線で結ばれているワードを検索）
    this.data.links.map((link) => {
      const t = link.target as GraphNode;
      const s = link.source as GraphNode;
      if (t.id === node.id) {
        t.isStrong = true;
        s.isStrong = true;
      } else if (s.id === node.id) {
        t.isStrong = true;
        s.isStrong = true;
      } else {
        t.isStrong = t.isStrong ? t.isStrong : false;
        s.isStrong = s.isStrong ? s.isStrong : false;
      }
    });

    const target = document.getElementById(this.targetIdName);
    if (!target) {
      return;
    }

    // クリックした要素を最前面にするために一番最後に順番を入れ替え
    const height = target.clientHeight;
    const idx = nodes.indexOf(node);
    if (idx !== -1) {
      nodes.splice(idx, 1);
      nodes.push(node);
    }
    this.data.nodes = nodes;

    // 並び替えによる座標更新
    d3.select('.nodes').selectAll('circle')
      .data(this.data.nodes)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => height - d.y);

    d3.select('.labels').selectAll('g')
      .data(this.data.nodes)
      .attr('transform', (d) => 'translate(' + d.x + ',' + (height - d.y) + ')');

    // クリックフラグをtrueにする
    this.nodeClickFlag = true;
  }

  /**
   * 関連キーワードの強調解除
   */
  private resetStrongNodeEntites() {

    const nodes = this.data.nodes.map((node) => {
      node.isStrong = false;
      node.isSelect = false;
      return node;
    });
    this.data.nodes = nodes;
  }

  /**
   * svgのクリックイベント
   */
  private svgClick() {
    if (this.nodeClickFlag) {
      // nodeがクリックされていたらイベントを実行しない
      this.nodeClickFlag = false;
      return;
    }

    // 関連ワードの強調を解除
    this.resetStrongNodeEntites();
  }

  /**
   * svgの移動操作のイベント
   */
  private svgStartMove(e: MouseEvent) {
    const mousePos = {
      x: e.offsetX,
      y: e.offsetY,
    };
    // 移動中の実処理
    const svgMove = (e2: MouseEvent) => {
      const diffPos = {
        x: e2.offsetX - mousePos.x,
        y: e2.offsetY - mousePos.y,
      };
      this.updateTransformSvg(null, diffPos);
      mousePos.x = e2.offsetX;
      mousePos.y = e2.offsetY;
    };
    const svgEndMove = () => {
      document.removeEventListener('mousemove', svgMove);
      document.removeEventListener('mouseup', svgEndMove);
    };
    document.addEventListener('mousemove', svgMove);
    document.addEventListener('mouseup', svgEndMove);
  }

  /**
   * svg画像のズームイベント
   */
  private svgZoom(e: WheelEvent) {
    this.viewScale -= e.deltaY / 1000;
    if (this.viewScale < graphConst.env.minScale) {
      this.viewScale = graphConst.env.minScale;
    } else if (this.viewScale > graphConst.env.maxScale) {
      this.viewScale = graphConst.env.maxScale;
    }
    const offset = {
      x: e.offsetX,
      y: e.offsetY,
    };
    this.updateTransformSvg(offset, null);
  }

  /**
   * svg画像上での移動と拡縮を制御
   */
  private updateTransformSvg(offset: Vector2 | null, diff: Vector2 | null) {
    const target = document.getElementById(this.targetIdName);
    if (!target) {
      return;
    }
    if (!offset) {
      offset = {
        x: target.clientWidth / 2,
        y: target.clientHeight / 2,
      };
    }
    if (!diff) {
      diff = {
        x: 0,
        y: 0,
      };
    }

    // pxから相対値になるように基準位置計算
    const rateX = offset.x / target.clientWidth;
    const rateY = offset.y / target.clientHeight;
    const sOffset = {
      x: this.viewTransRect.x1 + diff.x + (this.viewTransRect.width) * rateX,
      y: this.viewTransRect.y1 + diff.y + (this.viewTransRect.height) * rateY,
    };
    const width = target.clientWidth * this.viewScale;
    const height = target.clientHeight * this.viewScale;

    // svg内の座標の画角を計算して移動、拡縮を反映させる
    const svg = d3.select(target).select('svg');
    const layers = svg.selectAll('.layer');
    this.viewTransRect = {
      x1: sOffset.x - width * rateX,
      x2: sOffset.x + width * (1.0 - rateX),
      y1: sOffset.y - height * rateY,
      y2: sOffset.y + height * (1.0 - rateY),
      width,
      height,
    };
    layers.attr('transform',
      'translate(' + this.viewTransRect.x1 + ', ' + this.viewTransRect.y1 + ') scale(' + this.viewScale + ')');
  }

  /**
   * 線の表示・非表示設定
   */
  private isShowLine(link: GraphLink) {
    const t = link.target as GraphNode;
    const s = link.source as GraphNode;
    return link.isShow && t && t.isShow && s && s.isShow ? null : 'none';
  }

  /**
   * 線の太さ
   */
  private lineStrokeWidth(link: GraphLink) {
    const weight = link.weight * this.lineConst.rateWeight;
    return weight < this.lineConst.minWeight ? this.lineConst.minWeight : weight;
  }

  /**
   * 線の種類（直線か破線）
   */
  private lineType(link: GraphLink) {
    return link.line === 'solid' ? null : '2, 2';
  }

  /**
   * 線の色
   */
  private lineColor(link: GraphLink) {
    const t = link.target as GraphNode;
    const s = link.source as GraphNode;
    return t.isSelect || s.isSelect ? this.lineConst.selectColor : this.lineConst.color;
  }

  /**
   * 線の透明度
   */
  private lineOpacity(link: GraphLink) {
    const t = link.target as GraphNode;
    const s = link.source as GraphNode;
    return t.isSelect || s.isSelect ? null : this.lineConst.opacity;
  }

  /**
   * 円の半径
   */
  private circleR(node: GraphNode) {
    const range = node.size * this.circleConst.rateRange;
    return range < this.circleConst.minRange ? this.circleConst.minRange : range;
  }

  /**
   * 円の透明度
   */
  private circleOpacity(node: GraphNode) {
    if (this.data.nodes.find((n) => n.isStrong)) {
      return node.isStrong ? null : graphConst.notSelectionNodeOpacity;
    }
    return this.circleConst.opacity;
  }

  /**
   * 円の色
   */
  private circleColor(node: GraphNode) {
    return graphConst.groupColorList[(Number(node.com) - 1) % graphConst.groupColorList.length];
  }

  /**
   * ラベルの透明度
   */
  private labelOpacity(node: GraphNode) {
    if (this.data.nodes.find((n) => n.isStrong)) {
      return node.isStrong ? null : graphConst.notSelectionNodeOpacity;
    }
    return null;
  }

  /**
   * ラベルのフォントサイズ
   */
  private labelFontSize(node: GraphNode) {
    return node.isSelect ? this.labelConst.strongSize : this.labelConst.size;
  }
}
