




















import { Component, Prop, Vue } from 'vue-property-decorator';
import axios from 'axios';
import { graphConst } from '@/define';
import HeaderMenu from './../organisms/HeaderMenu.vue';
import ToolMenu from './../organisms/ToolMenu.vue';
import RangeMenu from './../organisms/RangeMenu.vue';
import GraphScreen from './../organisms/GraphScreen.vue';
import ErrorScreen from './../organisms/ErrorScreen.vue';
import GraphNode from '@/models/GraphNode';
import GraphLink from '@/models/GraphLink';

@Component({
  components: {
    HeaderMenu,
    ToolMenu,
    RangeMenu,
    GraphScreen,
    ErrorScreen,
  },
})
export default class TopPage extends Vue {
  public data: {
    graph: {
      nodes: GraphNode[],
      links: GraphLink[],
    },
    summary: {
      competitors: boolean[],
      landing_page: boolean,
      top10: boolean,
      user_page: boolean,
    },
    target: string,
    title: string,
    url: string,
    success: boolean;
  } | null = null;
  public options: {
    weight: number,
    groups: Array<{ com: string, color: string, isShow: boolean }>,
  } | null = null;

  public errMsg: string | null = null;

  public created() {
    const urlParams = new URLSearchParams(window.location.search);
    const callbackUrl = urlParams.get('callback_url');
    // ローカルで動作確認したい場合は下記
    // const callbackUrl = '/api/getKeywords2.json';
    if (!callbackUrl) {
      this.errMsg = 'コールバックURLが設定されていません。';
      return;
    }
    // データを取得
    axios.get(callbackUrl, {
      withCredentials: true,
    }).then((res: any) => {
        this.data = res.data as {
          graph: {
            nodes: GraphNode[],
            links: GraphLink[],
          },
          summary: {
            competitors: boolean[],
            landing_page: boolean,
            top10: boolean,
            user_page: boolean,
          },
          target: string,
          title: string,
          url: string,
          success: boolean,
        };
        if (!this.data || this.data.success === false) {
          this.errMsg = 'データが取得できませんでした。';
          return;
        }

        if (this.data.graph) {
          // リンクを予めweight順にソートしておく
          this.data.graph.links.sort((a, b) => {
            if (a.weight < b.weight) { return -1; }
            if (a.weight > b.weight) { return 1; }
            return 0;
          });

          // リンクの設定
          const linkCount = this.data.graph.links.length;
          this.data.graph.links.forEach((link, index) => {
            link.percentile = (index + 1) / linkCount;
            link.isShow = true;
          });

          // オプションの設定
          const groupList = this.data.graph.nodes
            .map((node) => node.com)
            .filter((node, i, self) => self.indexOf(node) === i)
            .sort()
            .map((com, index) => {
              return {
                com,
                color: graphConst.groupColorList[index % graphConst.groupColorList.length],
                isShow: true,
              };
          });

          // 線の初期表示数を決定
          let startWeight = 0;
          if (linkCount >= graphConst.env.startLinkNum) {
            startWeight = this.data.graph.links[linkCount - graphConst.env.startLinkNum].percentile;
          }

          this.options = {
            weight: startWeight,
            groups: groupList,
          };
        }
    }).catch((res: Error) => {
      this.errMsg = res.message;
    });
  }

  private changeSelectWeight(selectWeight: number) {
    if (!this.options) {
      return;
    }
    this.options.weight = selectWeight;
  }

  private changeSelectGroups(selectGroups: Array<{ com: string, color: string, isShow: boolean }>) {
    if (!this.options) {
      return;
    }
    this.options.groups = selectGroups;
  }
}
